import { useStaticQuery, graphql } from 'gatsby';

export default function allCategoryLearningVideoQuery() {
  const nodes = useStaticQuery(graphql`
    query CategoryLearningVideo {
      categories: allTaxonomyTermCategoryLearningVideo(sort: {fields: name, order: ASC}) {
        edges {
          node {
            uuid: id
            id: drupal_internal__tid
            title: name
            langcode
            featured: field_featured
            wysiwyg: field_description {
              summary
              processed
            }
            video_link: field_video_link {
              url: uri
            }
            link: path {
              uri: alias
              langcode
            }
            relationships {
              area: field_area {
                id: drupal_internal__tid
                name
              }
              featured_image: field_featured_image {
                name
              }
              image: field_image {
                name
                attributes: field_media_image {
                  alt
                  title
                }
              }
              parent {
                id: drupal_internal__tid
                name
              }
              children: taxonomy_term__category_learning_video {
                id: drupal_internal__tid
                title: name
                langcode
                wysiwyg: field_description {
                  summary
                  processed
                }
                link: path {
                  uri: alias
                  langcode
                }
                relationships {
                  image: field_image {
                    name
                    data: gatsbyImageData(layout: FIXED)
                    attributes: field_media_image {
                      alt
                      title
                    }
                  }
                  area: field_area {
                    id: drupal_internal__tid
                    name
                  }
                  faqs: field_faq_s {
                    relationships {
                      faqs: field_faq_s {
                        id
                        question: field_pg_title
                        answer: field_pg_wysiwyg {
                          processed
                        }
                      }
                    }
                  }
                  relatedContent: field_related_content {
                    type: __typename
                    ... on node__blog {
                      id
                      type: __typename
                      title
                      langcode
                      wysiwyg: body {
                        summary
                      }
                      link: path {
                        uri: alias
                        langcode
                      }
                      relationships {
                        image: field_thumbnail {
                          name
                          data: gatsbyImageData(layout: FIXED)
                          attributes: field_media_image {
                            alt
                            title
                          }
                        }
                      }
                    }
                    ... on node__whitepaper {
                      id
                      type: __typename
                      title
                      langcode
                      wysiwyg: body {
                        summary
                      }
                      link: path {
                        uri: alias
                        langcode
                      }
                      relationships {
                        image: field_thumbnail {
                          name
                          data: gatsbyImageData(layout: FIXED)
                          attributes: field_media_image {
                            alt
                            title
                          }
                        }
                      }
                    }
                    ... on node__success_story {
                      id
                      type: __typename
                      title
                      langcode
                      wysiwyg: body {
                        summary
                      }
                      link: path {
                        uri: alias
                        langcode
                      }
                      relationships {
                        image: field_image {
                          filename
                          data: gatsbyImageData(layout: FIXED)
                        }
                      }
                      image: field_image {
                        alt
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes;
}
