import React, {
  ReactElement, useCallback, useContext, useMemo, useRef, useState
} from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Accordion as AccordionBootstrap,
  AccordionContext,
  useAccordionToggle
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlayCircle, faChevronLeft, faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ReactPlayer from 'react-player';

import translateContent from '@core/helpers/translation';
import DomPurify from '@core/helpers/dom-purify';

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';
import Accordion from '@shared/accordion/accordion';
import Card from '@shared/card/card';
import Hero from '@shared/hero/hero';

import { Graphql } from '@models/graphql';

import allCategoryLearningVideoQuery from './default-query';

import './styles.scss';

const { learning: crumbs } = require('@config/breadcrumbs');

type LearningProps = {
  data: {
    learning: Graphql;
  };
};

type ToggleProps = React.PropsWithChildren<{
  eventKey: string;
  className?: string;
  callback?: (_eventKey: string) => void;
}>;

ContextAwareToggle.defaultProps = {
  callback: () => { },
  className: ''
};

function ContextAwareToggle({
  children, eventKey, callback, className
}: ToggleProps) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback?.(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Button
      variant="link"
      size="sm"
      style={{ minWidth: 'auto' }}
      onClick={decoratedOnClick}
      className={classNames('toggle', className, {
        'toggle--active': isCurrentEventKey
      })}
    >
      {children}
    </Button>
  );
}

function Sidebar({ learning }: { learning: Graphql }) {
  const queryData = allCategoryLearningVideoQuery();
  const [activeKey, setActiveKey] = useState('');
  const activated = useRef(false);

  const categories = useMemo(
    () => translateContent(queryData?.categories?.edges ?? [], learning.langcode)
      .map((category: Graphql) => (category.node))
      .filter((category) => !!category) as Graphql[],
    [queryData]
  );
  const rootElements = useMemo(() => categories.filter((item: Graphql) => {
    if (!item.relationships) {
      return false;
    }

    return item.relationships.parent?.length === 0;
  }), [categories]);

  return (
    <AccordionBootstrap as="ul" className="level-0" activeKey={activeKey}>
      {rootElements.map((item, i) => (
        <li id={`${item.uuid}`} key={`${item.uuid}`} className="learning-side-bar--item">
          <div className="d-flex justify-content-between align-items-center">
            {item.link && (
              <GatsbyLink to={item.link.uri || ''} className="text-marine font-weight-bold">
                {item.title}
              </GatsbyLink>
            )}
            {!!item.relationships?.children
              && (
                <ContextAwareToggle eventKey={`${i}`} callback={(key) => setActiveKey((prev) => (prev === key ? '' : key))}>
                  <FontAwesomeIcon icon={faChevronDown as IconProp} />
                </ContextAwareToggle>
              )}
          </div>
          <AccordionBootstrap.Collapse eventKey={`${i}`}>
            <SidebarItem
              categoryParent={item}
              level={1}
              learning={learning}
              categories={categories}
              setActiveParent={() => {
                if (!activated.current) {
                  setActiveKey(`${i}`);
                  activated.current = true;
                }
              }}
            />
          </AccordionBootstrap.Collapse>
        </li>
      ))}
    </AccordionBootstrap>
  );
}

type SidebarItemProps = {
  categoryParent: Graphql;
  level: number;
  learning: Graphql;
  categories: Graphql[];
  setActiveParent: () => void;
}

function SidebarItem({
  categoryParent, level, learning, categories, setActiveParent
}: SidebarItemProps) {
  const [activeKey, setActiveKey] = useState('');
  const activated = useRef(false);

  if (level > 3 || !categoryParent || !categoryParent.relationships) {
    return <></>;
  }

  const children = categoryParent.relationships.children || [];

  if (children.length === 0) {
    return null;
  }

  const handleActive = useCallback((key: string) => {
    if (!activated.current) {
      setActiveParent();
      setActiveKey(key);
      activated.current = true;
    }
  }, []);

  return (
    <AccordionBootstrap as="ul" className={`pl-3 level-${level}`} activeKey={activeKey}>
      {children?.map((item, i) => {
        const category = categories.find((c) => c.id === item.id);
        const isActive = learning.tid === category?.id;

        if (isActive) {
          handleActive(`${i}`);
        }

        if (!category) {
          return null;
        }

        const hasChildren = category.relationships?.children
          ?.some((c) => c.langcode === learning.langcode);

        return (
          <li key={category.id}>
            <div className="d-flex justify-content-between align-items-center">
              <GatsbyLink
                to={category?.link?.uri || ''}
                className={classNames('text-base', {
                  'active text-tangerine text-bold text-underline': isActive,
                  'text-emperor-grey': !isActive,
                })}
              >
                {category.title}
              </GatsbyLink>
              {hasChildren
                && (
                  <ContextAwareToggle eventKey={`${i}`} callback={(key) => setActiveKey((prev) => (prev === key ? '' : key))}>
                    <FontAwesomeIcon className="text-marine" icon={faChevronDown as IconProp} />
                  </ContextAwareToggle>
                )}
            </div>
            <AccordionBootstrap.Collapse eventKey={`${i}`}>
              <SidebarItem
                categoryParent={category}
                level={level + 1}
                learning={learning}
                categories={categories}
                setActiveParent={() => {
                  handleActive(`${i}`);
                }}
              />
            </AccordionBootstrap.Collapse>
          </li>
        );
      })}
    </AccordionBootstrap>
  );
}

export default function LearningPage({ data }: LearningProps): React.ReactElement {
  const { t } = useTranslation();
  const [isVideoOpen, setVideoOpen] = useState(false);

  const { learning } = data;
  const { relationships } = learning || {};
  const { faqs } = relationships || {};
  const faqsItems = (faqs?.relationships?.faqs || []) as Graphql[];

  let isFile = false;
  let source = learning?.link?.uri || undefined;

  if (learning?.relationships?.video?.relationships?.file?.src?.url) {
    source = learning.relationships.video.relationships.file.src.url;
    isFile = true;
  }

  const getVideoComponent = () => {
    if (isFile) {
      return (
        <video
          width="100%"
          height="auto"
        >
          <source src={`${source}#t=1`} />
          <track default kind="captions" srcLang="en" />
        </video>
      );
    }

    return (
      <ReactPlayer
        className="react-player"
        onClickPreview={() => setVideoOpen(true)}
        light
        width="100%"
        height="100%"
        url={source || ''}
      />
    );
  };

  return (
    <div className="learning--page">
      {learning?.relationships?.hero && (
        <div className="hero-container mb-5">
          <Hero node={learning.relationships.hero} />
          <div className="hero-container-banner-cta">
            <Container className="d-flex justify-content-between py-4">
              <GatsbyLink to={t('Path learning page')}>
                <span className="text-uppercase">
                  <FontAwesomeIcon className="mr-2" size="lg" icon={faChevronLeft as IconProp} />
                  {t('Back to categories')}
                </span>
              </GatsbyLink>
              <GatsbyLink to={t('Path video page')}>
                <span className="text-uppercase">
                  {t('Go to video gallery')}
                </span>
              </GatsbyLink>
            </Container>
          </div>
        </div>
      )}
      <Breadcrumb crumbs={crumbs.concat([{ label: learning.title, link: '#' }])} />
      <Container className="mb-5 mt-3">
        <Row>
          <Col className="mb-5 mb-md-0" md={5} lg={3}>
            <div className="learning-side-bar">
              <Sidebar learning={learning} />
            </div>
          </Col>
          <Col md={7} lg={9}>
            {
              source
              && (
                <div className="video-container">
                  <div className="player-wrapper">
                    <Button variant="link" onClick={() => setVideoOpen(true)} className="player-wrapper--icon w-100 h-100">
                      <FontAwesomeIcon size="2x" icon={faPlayCircle as IconProp} />
                    </Button>
                    {getVideoComponent()}
                  </div>
                </div>
              )
            }
            <h2 className="title">
              {learning.title}
            </h2>
            {learning?.wysiwyg?.processed && (
              <div className="wysiwyg mt-3 mb-3">
                <DomPurify text={learning.wysiwyg.processed} />
              </div>
            )}

            {faqsItems.length > 0 && (
              <Accordion
                options={faqsItems}
                title={((title: Graphql) => title?.question)}
                content={
                  // eslint-disable-next-line react/no-unstable-nested-components
                  (content) => (
                    <DomPurify
                      text={content?.answer?.processed || ''}
                      includeIndex
                    />
                  )
                }
              />
            )}
          </Col>
          {relationships && (relationships.relatedContent || []).length > 0 && (
            <RelatedContents relatedContents={relationships.relatedContent} />
          )}
        </Row>
      </Container>
      <Modal
        show={isVideoOpen}
        onHide={() => setVideoOpen(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {learning.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center py-5">
          <iframe
            title={`${learning.title}`}
            src={source}
            frameBorder="0"
            height="500"
            width="100%"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export const query = graphql`
query($id: String!) {
  learning: taxonomyTermCategoryLearningVideo(id: { eq: $id }) {
    id
    tid: drupal_internal__tid
    title: name
    langcode
    wysiwyg: field_description {
      processed
    }
    link: field_video_link {
      uri
    }
    relationships {
      area: field_area {
        name
      }
      parent {
        id: drupal_internal__tid
        name
      }
      faqs: field_faq_s {
        relationships {
          faqs: field_faq_s {
            id
            question: field_pg_title
            answer: field_pg_wysiwyg {
              processed
            }
          }
        }
      }
      relatedContent: field_related_content {
        type: __typename
        ... on node__blog {
          id
          type: __typename
          title
          langcode
          wysiwyg: body {
            summary
          }
          link: path {
            uri: alias
            langcode
          }
          relationships {
            image: field_thumbnail {
              name
              data: gatsbyImageData(layout: FIXED)
              attributes: field_media_image {
                alt
                title
              }
            }
          }
        }
        ... on node__whitepaper {
          id
          type: __typename
          title
          langcode
          wysiwyg: body {
            summary
          }
          link: path {
            uri: alias
            langcode
          }
          relationships {
            image: field_thumbnail {
              name
              data: gatsbyImageData(layout: FIXED)
              attributes: field_media_image {
                alt
                title
              }
            }
          }
        }
        ... on node__success_story {
          id
          type: __typename
          title
          langcode
          wysiwyg: body {
            summary
          }
          link: path {
            uri: alias
            langcode
          }
          relationships {
            image: field_image {
              filename
              data: gatsbyImageData(layout: FIXED)
            }
          }
          image: field_image {
            alt
            title
          }
        }
      }
      video: field_video_file {
        relationships {
          file: field_media_video_file {
            src: localFile {
              publicURL
              url
            }
          }
        }
      }
      hero: field_hero {
        ...HeroParagraph
      }
    }
  }
}`;

type RelatedContentsProps = {
  relatedContents: Graphql[] | undefined;
};

function RelatedContents({ relatedContents }: RelatedContentsProps): ReactElement {
  if (!relatedContents || relatedContents.length <= 0) {
    return <></>;
  }

  const { t } = useTranslation();

  return (
    <>
      {
        relatedContents.length > 0 && (
          <div className="interested-links mt-4">
            <Row>
              <Col sm={12}>
                <h4 className="mt-4 mb-4">{t('Related Content')}</h4>
              </Col>
              {
                relatedContents.map((interest) => (
                  <Col className="mb-6 my-3" key={interest.id} sm={12} md={6} lg={4}>
                    <Card key={interest.id} node={interest} />
                  </Col>
                ))
              }
            </Row>
          </div>
        )
      }
    </>
  );
}
